import Vue from 'vue'
import { ProgramFees } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import Address from '/~/composables/addresses/core/Address'
import {
  FlowType,
  SelectedPaymentMethodCreditCard,
} from '/~/composables/checkout/checkout-types'
import Checkout, {
  CardVerificationConfig,
} from '/~/composables/checkout/core/Checkout'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'

const { burnPointsRatePaymentOrder, calculatePointsEarnedForPayment } =
  usePoints()
const { isMethodAvailable } = usePaymentMethods()

export class PaymentCheckout extends Checkout {
  orderType = FlowType.payment
  cardVerificationType = 'payees' as const
  reference = ''

  constructor() {
    super()

    if (this.constructor === PaymentCheckout) {
      throw new Error('Abstract classes can not be instantiated')
    }

    emitter.on('addresses:remove', (address) => {
      if (this.address && address && this.address.id === address.id) {
        this.address = null
      }
    })
  }

  get burnPointsRate() {
    return burnPointsRatePaymentOrder.value
  }

  get pointsEarned() {
    return calculatePointsEarnedForPayment(this.subTotal)
  }

  get payeeId(): string | null {
    throw new Error(
      `Property "payeeId" is not implemented for the class ${this.constructor.name}`
    )
  }

  get canAddPaymentMethods() {
    return (
      isMethodAvailable(PaymentMethodType.creditCard, FlowType.payment) ||
      isMethodAvailable(PaymentMethodType.bankAccount, FlowType.payment)
    )
  }

  get rootRoute() {
    return {
      name: 'payments-select-payee',
      params: {
        type: 'primary',
      },
    }
  }

  async getProgramFees() {
    const amount = this.subTotal

    if (amount === 0) {
      return
    }

    this.loadingProgramFees = true

    try {
      const payload: { amount: number; payeeId?: string } = { amount }

      if (this.payeeId) {
        payload.payeeId = this.payeeId
      }

      const { data } = await api.post<ProgramFees>(
        '/v3/program-fees',
        payload,
        { notify: false }
      )

      this.programFeesData = data
      this.programFees = Number(data?.totalFees)
      this.programFeesPercentage = Number(data?.fees[0]?.percentage ?? 0)
    } catch (error: any) {
      Vue.notify({
        type: 'error',
        text: 'Something went wrong loading program fees',
      })

      console.error((error.data && error.data.message) || error)
    } finally {
      this.loadingProgramFees = false
    }
  }

  getTransactionFeesPayload() {
    const payload = super.getTransactionFeesPayload()

    if (this.payeeId) {
      payload.paymentDestinations = [
        {
          payeeId: this.payeeId,
          amount: String(this.subTotal),
        },
      ]
    }

    return payload
  }

  async getTransactionFees() {
    this.feesSuccess = false

    if (!this.payeeId) {
      this.transactionFees = 0
      this.feesSuccess = true

      return
    }

    return super.getTransactionFees()
  }

  getPayload() {
    if (!this.payeeId || !this.address) {
      return
    }

    return {
      ...super.getPayload(),
      addressId: String(this.address.id),
      reference: this.reference,
      paymentDestinations: [
        {
          payeeId: this.payeeId,
          amount: String(this.subTotal),
        },
      ],
    }
  }

  getCardVerificationPayload({
    card,
    address,
    cardVerification,
  }: {
    card: SelectedPaymentMethodCreditCard
    address?: Address
    cardVerification?: CardVerificationConfig
  }) {
    return {
      card,
      amount: this.payWithCard + this.transactionFees,
      subTotal: this.subTotal,
      address: address ?? this.address,
      multipleSources: this.multipleSourcesSelected,
      modalsConfig: cardVerification?.modalsConfig,
      type: this.cardVerificationType,
      payeeId: this.payeeId || undefined,
    }
  }
}

export default PaymentCheckout
