import { computed, reactive } from 'vue'
import StatementCheckout from '/~/composables/checkout/core/Checkout/PaymentCheckout/StatementCheckout'

const checkout = reactive(new StatementCheckout())

const address = computed({
  get() {
    return checkout.address
  },
  set(_address) {
    checkout.address = _address
  },
})
const securityToken = computed(() => checkout.securityToken)
const cvv = computed(() => checkout.cvv)
const confirmation = computed(() => checkout.confirmation)
const isCombineFees = computed(() => checkout.isCombineFees)
const isPointsDisabled = computed(() => checkout.isPointsDisabled)
const isCreditCardsDisabled = computed(() => checkout.isCreditCardsDisabled)
const isBankAccountsDisabled = computed(() => checkout.isBankAccountsDisabled)
const isEwalletDisabled = computed(() => checkout.isEwalletDisabled)
const isScheduled = computed(() => checkout.isActiveScheduledPayment)
const payWithPayId = computed(() => checkout.payWithPayId)
const payWithPoints = computed(() => checkout.payWithPoints)
const payWithEwallet = computed(() => checkout.payWithEwallet)
const payWithCoupons = computed(() => checkout.payWithCoupons)
const payWithCard = computed(() => checkout.payWithCard)
const payWithBankAccount = computed(() => checkout.payWithBankAccount)
const isReadyForPayment = computed(() => checkout.isReadyForPayment)
const transactionFees = computed(() => checkout.transactionFees)
const programFees = computed(() => checkout.programFees)
const loadingFees = computed(() => checkout.loadingFees)
const totalFees = computed(() => checkout.totalFees)
const subTotal = computed(() => checkout.subTotal)
const subTotalNoCoupons = computed(() => checkout.subTotalNoCoupons)
const subTotalWithProgramFees = computed(() => checkout.subTotalWithProgramFees)
const subTotalWithProgramFeesNoCoupons = computed(
  () => checkout.subTotalWithProgramFeesNoCoupons
)
const total = computed(() => checkout.total)
const selectedAmount = computed(() => checkout.selectedAmount)
const readyToPay = computed(() => checkout.readyToPay)
const isEnoughPointsPaymentOrder = computed(() => checkout.isEnoughPointsOrder)
const pointsEarned = computed(() => checkout.pointsEarned)
const isCardOrBankAccountMethodSelected = computed(
  () => checkout.isCardOrBankAccountMethodSelected
)
const selectedPayId = computed({
  get() {
    return checkout.selectedPayId
  },
  set(_selectedPayId) {
    checkout.selectedPayId = _selectedPayId
  },
})
const selectedPoints = computed({
  get() {
    return checkout.selectedPoints
  },
  set(_selectedPoints) {
    checkout.selectedPoints = _selectedPoints
  },
})
const selectedEwallet = computed({
  get() {
    return checkout.selectedEwallet
  },
  set(_selectedEwallet) {
    checkout.selectedEwallet = _selectedEwallet
  },
})
const selectedCoupons = computed({
  get() {
    return checkout.selectedCoupons
  },
  set(_selectedCoupons) {
    checkout.selectedCoupons = _selectedCoupons
  },
})
const selectedCard = computed({
  get() {
    return checkout.selectedCard
  },
  set(_selectedCard) {
    checkout.selectedCard = _selectedCard
  },
})
const selectedBankAccount = computed({
  get() {
    return checkout.selectedBankAccount
  },
  set(_selectedBankAccount) {
    checkout.selectedBankAccount = _selectedBankAccount
  },
})
const fetchStatement = checkout.getOrder.bind(checkout)
const reset = checkout.reset.bind(checkout)
const resetMethods = checkout.resetCouponsAndPaymentMethods.bind(checkout)
const getProgramFees = checkout.getProgramFees.bind(checkout)
const getTransactionFees = checkout.getTransactionFees.bind(checkout)
const finishPayment = checkout.finishPayment.bind(checkout)
const pay = checkout.pay.bind(checkout)
const getPaymentOrder = checkout.getOrder.bind(checkout)
const preview = checkout.preview.bind(checkout)
const initPayment = checkout.initPayment.bind(checkout)
const multipleSourcesSelected = computed(() => checkout.multipleSourcesSelected)

export function useStatementCheckout() {
  return {
    payment: checkout,
    fetching: computed(() => checkout.fetching),
    submitting: computed(() => checkout.submitting),
    statement: computed(() => checkout.statement),
    address,
    securityToken,
    cvv,
    fetchStatement,
    isReadyForPayment,
    loadingFees,
    transactionFees,
    programFees,
    totalFees,
    subTotal,
    subTotalNoCoupons,
    subTotalWithProgramFees,
    subTotalWithProgramFeesNoCoupons,
    total,
    selectedAmount,
    pointsEarned,
    payWithPayId,
    payWithPoints,
    payWithEwallet,
    payWithBankAccount,
    payWithCard,
    payWithCoupons,
    readyToPay,
    selectedPayId,
    selectedCard,
    selectedPoints,
    selectedEwallet,
    selectedBankAccount,
    selectedCoupons,
    isPointsDisabled,
    isCreditCardsDisabled,
    isBankAccountsDisabled,
    isEwalletDisabled,
    isScheduled,
    isCardOrBankAccountMethodSelected,
    confirmation,
    isCombineFees,
    getTransactionFees,
    reset,
    resetPayment: reset,
    resetMethods,
    getProgramFees,
    finishPayment,
    pay,
    isEnoughPointsPaymentOrder,
    initPayment,
    preview,
    getPaymentOrder,
    multipleSourcesSelected,
  }
}
